"use client";

import Header from "@/components/HeaderContainer/HeaderContainer";
import HomeSlider from "@/components/HomeSlider/HomeSlider";
import { HomeSlide } from "@/components/HomeSlider/types";
import { PackageListData } from "@/types/page";
import MostPreferredPackages from "../../components/MostPreferredPackages/MostPreferredPackages";
import WhyDoping from "@/components/WhyDoping/WhyDoping";
import SingleVideos from "@/components/SingleVideos/SingleVideos";
import SampleVideos from "@/components/SampleVideos/SampleVideos";
import DayCalcList from "@/components/DayCalcList/DayCalcList";
import Testimonials from "@/components/Testimonials/Testimonials";
import Footer from "@/components/Footer/Footer";
import CartLink from "@/components/CartLink/CartLink";
import JsonLd from "@/components/JsonLd/JsonLd";
import { linkUrl, rtbhSender } from "@/helpers";
import { useEffect } from "react";

export default function HomeView({ menuData, footerData, mobileMenuData, homeData, slider, sampleVideos }: {
  readonly menuData: Record<string,any>,
  readonly mobileMenuData: Record<string,any>,
  readonly footerData: Record<string,any>,
  readonly homeData: Record<string,any>,
  readonly slider: HomeSlide[],
  readonly sampleVideos: Record<string,any>,
}) {
  const testimonials: Record<string,any>[] = [];

  /* Filter out videos */
  (homeData?.testimonials ?? []).forEach((item: Record<string,any>) => {
    if(!item.video_url) {
      testimonials.push({ ...item });
    }
  });

  useEffect(() => {
    rtbhSender({ eventType: 'home' });
  }, []);

  return (
    <>
      <Header transparent={true} animated={true} content={menuData} mobileMenu={mobileMenuData} />

      <HomeSlider
        delay={5000}
        bgColor="#04275df0"
        slides={slider}
        loop={true}
        domPersistent={true}
      />
      
      <main className="">
        
        <section>
          <MostPreferredPackages packageData={homeData as PackageListData} className="mt-6" gtmListId="package_list" />
        </section>

        <section>
          <WhyDoping
            data={ homeData?.why_us_videos ?? [] }
            caption={ homeData?.why_us_caption ?? '' }
            subCaption={ homeData?.why_us_subcap ?? '' }
          />
        </section>

        <section>
          <SingleVideos
            videos={ homeData?.single_videos ?? [] }
            domPersistent={true}
          />
        </section>

        <section>
          <SampleVideos
            rootSlug={sampleVideos.rootSlug}
            videos={sampleVideos.videos}
          />
        </section>

        <section>
          <DayCalcList
            list={ homeData?.calc_groups }
            heading={{
              caption: homeData?.day_calc_caption ?? '',
              subcap: homeData?.day_calc_subcap ?? '',
              strongCap: homeData?.day_calc_strong_cap ?? '',
              bgUrl: homeData?.day_calc_bg_url ?? '',
            }}
          />
        </section>

        <section>
          <Testimonials
            list={ testimonials ?? [] }
            rootSlug={ homeData?.testimonials_slug ?? '' }
            heading={{
              caption: homeData?.testimonials_caption ?? '',
              subcap: homeData?.testimonials_subcap ?? '',
              linkCap: homeData?.testimonials_link_cap ?? '',
            }}
          />
        </section>
      </main>

      <Footer menuData={menuData} footerData={footerData} />
      <CartLink />
      
      {/* Sticky Phone */}
      {/* <div className="bg-lava text-white fixed md:hidden bottom-0 left-0 right-0 rounded-t-lg z-[997]" data-sticky-phone>
        <LoadingLink href={menuData?.phone?.url ?? 'tel:+902122367441'} className="sticky-phone-event flex justify-center items-center w-full p-2 text-2xl">
          <Image alt="" className="sticky-phone-event me-2" width={36} height={36} data-src="https://assets-dopinghafiza.dopingtech.net/assets/images/phone.svg" src="https://assets-dopinghafiza.dopingtech.net/assets/images/phone.svg" />
          { _e('Bizi Arayın') }
        </LoadingLink>
      </div> */}

      <JsonLd data={{
        "@context": "https://schema.org",
        "@type": "VideoGallery",
        "itemListElement": homeData.all_videos?.map((item: Record<string,any>, index: number) => {
          return {
            "@type": "VideoObject",
            "position": index + 1,
            "name": item?.caption,
            "description": item?.caption,
            "thumbnailUrl": linkUrl((process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/images/video-cover/', item.video_poster_url) ?? '',
            "contentUrl": linkUrl((process.env.NEXT_PUBLIC_VIDEO_ROOT ?? '') + '/ornek-videolar/', item.video_url) ?? '',
            "uploadDate": homeData.updatedAt,
          };
        })
      }} />
    </>
  );
}
